










































import {Component, Prop, Vue} from 'vue-property-decorator';

import LoadingHandler from "@/utils/loading-handler";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import {IFileDefaultValue} from "@/contracts/i-file";
import {ReadAsType} from "@/components/Form/OnFileSelectedHandler";
import ProductRepository from "@/repositories/company/product-repository";

@Component({
  components: {SimpleFormItem}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private value!:boolean;

  private get visible() { return this.value; }
  private set visible(val) { this.$emit('input', val); }

  private readonly ReadAsType = ReadAsType;

  private file = IFileDefaultValue;
  private errors:string[] = [];

  private async created() {
  }

  private async importCsv() {
    if (!this.file.body) {
      return;
    }
    LoadingHandler(async () => {
      const res = await (new ProductRepository(this.companyId)).bulkImportRecipe(this.file);
      const msg =
        `【ファイル数】${res.counts.target}件、` +
        `【商品】作成${res.counts.productCreated}件、更新${res.counts.productUpdated}件、` +
        `【中間原材料】作成${res.counts.preproductCreated}件、更新${res.counts.preproductUpdated}件`;

      this.$message({
        type: 'success',
        message: `インポートが完了しました。（${msg}）`
      });
    })
      .then(async () => {
        this.$emit('imported');
        this.file = IFileDefaultValue;
        this.visible = false;
      });
  }
}

